import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useContext, useEffect, useState } from "react";
import { Context } from "../contexts/general";
import http from "../libs/http";
import { useNavigate, redirect } from "react-router-dom";
import validatePassword from "./scripts/validatePassword";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
function SignUp(){
	const [user,setUser] = useState("")
	const [firstname,SetFirstname] = useState("")
	const [lastname,SetLastname] = useState("")
	const [phone,setPhone] = useState("")
	const [address,setAddress] = useState("")
	const [password,setPassword] = useState("")
	const [showPassword,setShowPassword] = useState(false)
	const [pwError,setPwError] = useState("")
	const [confirmPassword,setConfirmPassword] = useState("")
	const [showConfirmPassword,setShowConfirmPassword] = useState(false)
	const [cpwError,setCpwError] = useState("")
	const [source,setSource] = useState("")
	const [loading,setLoading] = useState(false)
	const [done,setDone] = useState(false)
	const [err,setErr] = useState()
	const navigate = useNavigate()
	const signup = () => {
		setLoading(true)
		setErr()
		let body = {pw: password}
		if(user.includes("@")) body.eml = user
		else body.usr = user
		body.fname = firstname
		body.lname = lastname
		body.ref = parseInt(source)
		body.addr = address
		body.phn = phone
		http.post({url:"users/client/signup",body}).then(res=>{
			console.log(res)
			if(res.data){
				if(res.data.error){
					setLoading(false)
					return setErr(res.data.error.message + ": " + res.data.error.data.message)
				}
				if(res.status !== 200){
					setLoading(false)
					if(res.status === 404 && res.statusText === "Not Found") return setErr("No se pudo conectar al servidor")
					return setErr(`${res.status} ${res.statusText}`)
				}
				//let data = res.data.data
				//setAuth(data.tkn)
				//delete data.tkn
				//delete data.skt_tkn
				//setUserInfo(data)
				setDone(true)
				//return navigate("/rectpack/home",{replace:true,state:{from:"signup",msg:"ok"}})
			}
			setLoading(false)
		})
	}
	if(done) return <Box sx={{transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2
		,"> *":{mb:2}
	}}><Typography>Registro finalizado</Typography>
	<Typography>Confirma tu correo para finalizar con el proceso y poder ingresar</Typography>
	<Button onClick={()=>{navigate("/rectpack/home",{replace:true,state:{from:"signup",msg:"ok"}})}} sx={{mt:2,position:"relative",width:"max-content",mx:"auto"}} variant="contained">
			Volver al inicio
		</Button>
	</Box>
	return <Box sx={{transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2
		,"> *":{mb:2}
	}}>
		<Typography sx={{color:"rgba(0,0,0,0.6)",fontSize:"1.5rem",pointerEvents:"none",userSelect:"none"}} component="b">
			Registro
		</Typography>
		<TextField disabled={loading}
			id="user" label="Correo" type="email" variant="standard"
			value={user} onInput={(ev)=>{setUser(ev.target.value)}}/>
		<TextField disabled={loading}
			id="fname" label="Nombres" type="text" variant="standard"
			value={firstname} onInput={(ev)=>{SetFirstname(ev.target.value)}}/>
		<TextField disabled={loading}
			id="lname" label="Apellidos" type="text" variant="standard"
			value={lastname} onInput={(ev)=>{SetLastname(ev.target.value)}}/>
		<TextField disabled={loading}
			id="phone" label="Teléfono" type="phone" variant="standard"
			value={phone} onInput={(ev)=>{setPhone(ev.target.value)}}/>
		<TextField disabled={loading}
			id="address" label="Dirección" type="text" variant="standard"
			value={address} onInput={(ev)=>{setAddress(ev.target.value)}}/>
		<FormControl variant="standard" sx={{minWidth:"12rem"}}>
			<InputLabel id="source-label">¿Cómo supiste de nosotros?</InputLabel>
			<Select labelId="source-label" sx={{textAlign:"left"}} id="source" value={source} defaultValue={""} onChange={(ev)=>{setSource(ev.target.value)}} label="¿Cómo supiste de nosotros?" disabled={loading}>
				<MenuItem value={0}>Facebook</MenuItem>
				<MenuItem value={1}>Instagram</MenuItem>
				<MenuItem value={2}>TikTok</MenuItem>
				<MenuItem value={3}>Ninja Master Class</MenuItem>
				<MenuItem value={4}>Recomendado por alguien</MenuItem>
				<MenuItem value={5}>Otro</MenuItem>
			</Select>
		</FormControl>
		<TextField disabled={loading}
			id="password" label="Contraseña" type={showPassword ? "text" : "password"} variant="standard"
			InputProps={{endAdornment:(<InputAdornment position="end"><IconButton size="small" onClick={()=>{setShowPassword(!showPassword)}} tabIndex={-1}>{showPassword ? <VisibilityOff/> : <Visibility/>}</IconButton></InputAdornment>)}}
			value={password} onInput={(ev)=>{
				const val = ev.target.value
				if(!validatePassword(val)) setPwError("La contraseña no cumple con las condiciones de seguridad")
				else setPwError()
				if(confirmPassword && (password !== confirmPassword)) setCpwError("Las contraseñas no coinciden")
				setPassword(val)
			}}/>
		{pwError && <Typography color="error">{pwError}</Typography>}
		<TextField disabled={loading}
			id="cpassword" label="Confirmar contraseña" type={showConfirmPassword ? "text" : "password"} variant="standard"
			InputProps={{endAdornment:(<InputAdornment position="end"><IconButton size="small" onClick={()=>{setShowConfirmPassword(!showConfirmPassword)}} tabIndex={-1}>{showConfirmPassword ? <VisibilityOff/> : <Visibility/>}</IconButton></InputAdornment>)}}
			value={confirmPassword} onInput={(ev)=>{
				const val = ev.target.value
				if(val !== password) setCpwError("Las contraseñas no coinciden")
				else setCpwError()
				setConfirmPassword(ev.target.value)
			}}/>
		{cpwError && <Typography color="error">{cpwError}</Typography>}
		<Button disabled={loading} onClick={signup} sx={{mt:2,position:"relative"}} variant="contained">
			Registrarse
			{loading && <CircularProgress sx={{mx:"auto",position:"absolute"}}/>}
		</Button>
		{err && <Typography mt={1} color="error">{err}</Typography>}
	</Box>
}
export default SignUp;